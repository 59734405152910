import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LoginFormComponent } from '@shared/login-form/login-form.component';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'ess-login-modal',
  imports: [LoginFormComponent, MatIconModule, MatDialogModule, TranslocoDirective],
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent {
  constructor(private dialogRef: MatDialogRef<LoginModalComponent>) {}
  onLoginDone() {
    this.dialogRef.close(true);
  }
  onModalClose() {
    this.dialogRef.close(false);
  }
}
